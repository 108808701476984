import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from './models/user';
import {Xmfile} from './models/xmfile';
import {UserfilesConfirms} from './models/userfilesConfirms';
import {Settings} from './models/settings';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  static appfullhost = environment.appfullhost;
  static pushfullhost = environment.pushfullhost;


  constructor(
    private http: HttpClient
  ) { }



  async getUser(userid: string): Promise<User|undefined>{
      let err = '';
      const user = await this.http.get(HttpService.appfullhost + 'users/' + userid ).toPromise().catch((error)=> err = undefined) as User;
      return user||undefined;
  }


  async getFile(fileName: string): Promise<any> {
    return await this.http.get(HttpService.appfullhost + 'getfile?filename=' + fileName, {responseType: 'blob'}).toPromise();
  }


  async confirmUserFile(userFileConfirm: UserfilesConfirms) {
    return await this.http.patch(HttpService.appfullhost + 'fileconfirms/' + userFileConfirm.ID, userFileConfirm).toPromise() as UserfilesConfirms;
  }

  async readUserFile(userFileConfirm: UserfilesConfirms) {
    return await this.http.post(HttpService.appfullhost + 'fileconfirms', userFileConfirm).toPromise() as UserfilesConfirms;
  }



  async getUserFilesConfirms(user: User): Promise<UserfilesConfirms[]> {
    return await this.http.get(HttpService.appfullhost + 'fileconfirms?filter[where][userid]='+user.ID).toPromise() as UserfilesConfirms[];
  }

  async getFiles(userid = ''): Promise<Xmfile[]> {
    return await this.http.get(HttpService.appfullhost + 'getfilelist/' + userid).toPromise() as Xmfile[];
  }

  async getBasicSettings(): Promise<Settings[]> {
    const settings = await this.http.get(HttpService.appfullhost + 'settings').toPromise() as Settings[];
    return settings;
  }
}

import { Component } from '@angular/core';
import {MainService} from './main.service';
import {MenuItem} from './models/menu/menuItem';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  userAccount = new MenuItem('Mein Konto', 'user/useraccount', 'assets/icon/user-icon.svg' );

  menuPages: MenuItem[] = [
    /*new MenuItem('Nachrichten', '/user/messenger', 'assets/icon/messenger-icon.svg'),*/
    new MenuItem('DOKUMENTEN', '#', 'assets/icon/document-svgrepo-com.svg',
      [
        new MenuItem('Downloadbereich', '/user/documents/download', 'assets/icon/downloadarea-icon.svg'),
        new MenuItem('Informationsbereich', '/user/documents/info', 'assets/icon/informationarea-icon.svg'),
        new MenuItem('Dienstplan', '/user/documents/workplan', 'assets/icon/document-svgrepo-com.svg'),
        new MenuItem('Mitarbeiterstundennachweis', '/user/documents/employeeproof', 'assets/icon/time-proof.svg'),
        new MenuItem('Standards', '#', 'assets/icon/documentsadministration-icon.svg',
          [
            new MenuItem('Grundpflegestandards', 'user/documents/standards|basic', 'assets/icon/documentsadministration-icon.svg'),
            new MenuItem('Behandlungspflegestandards', 'user/documents/standards|care', 'assets/icon/documentsadministration-icon.svg'),
            new MenuItem('Notfallstandards', 'user/documents/standards|emergency', 'assets/icon/documentsadministration-icon.svg'),
            new MenuItem('Nich medizinische Notfallstandards', 'user/documents/standards|notmedicalemergency', 'assets/icon/documentsadministration-icon.svg'),
            new MenuItem('Expertenstandards', 'user/documents/standards|experts', 'assets/icon/documentsadministration-icon.svg'),
            new MenuItem('Prophylaxestandards', 'user/documents/standards|prophylaxes', 'assets/icon/documentsadministration-icon.svg'),
          ]),
      ], false, true),
    new MenuItem('Wunschfrei', '/user/wishfree', 'assets/icon/wishfree-icon.svg'),
    new MenuItem('Dienstplan Gestaltungswünsche', '/user/workplane', 'assets/icon/workplan-icon.svg'),
    //new MenuItem('Urlaub', '/user/vacation', 'assets/icon/vacation-icon.svg'),
    new MenuItem('Zeiterfassung', '/user/timeline', 'assets/icon/worktime-icon.svg'),
    //new MenuItem('Monatsbestellungen', '/user/monthlyorders', 'assets/icon/monthlyorders-icon.svg'),
    new MenuItem('Administration', 'administration', 'assets/icon/administration-icon.svg',
      [
        new MenuItem('Benutzer Administration', 'administration/useradministration', 'assets/icon/useradministration-icon.svg'),
        new MenuItem('Wunschfrei/Kalender', 'administration/wishfreedashboard', 'assets/icon/wishfree-icon.svg'),
        new MenuItem('Dateiverwaltung', '#', 'assets/icon/documentsadministration-icon.svg',
          [
            new MenuItem('Alle Dateien', 'administration/files', 'assets/icon/documentsadministration-icon.svg'),
            new MenuItem('Downloads', 'administration/files/download', 'assets/icon/documentsadministration-icon.svg'),
            new MenuItem('Infos', 'administration/files/info', 'assets/icon/documentsadministration-icon.svg'),
            new MenuItem('Dienstplans', 'administration/files/workplan', 'assets/icon/documentsadministration-icon.svg'),
            new MenuItem('Mitarbeiterstundennachweis', 'administration/files/employeeproof', 'assets/icon/documentsadministration-icon.svg'),
            new MenuItem('Standards', '#', 'assets/icon/documentsadministration-icon.svg',
              [
                new MenuItem('Grundpflegestandards', 'administration/files/standards|basic', 'assets/icon/documentsadministration-icon.svg'),
                new MenuItem('Behandlungspflegestandards', 'administration/files/standards|care', 'assets/icon/documentsadministration-icon.svg'),
                new MenuItem('Notfallstandards', 'administration/files/standards|emergency', 'assets/icon/documentsadministration-icon.svg'),
                new MenuItem('Nich medizinische Notfallstandards', 'administration/files/standards|notmedicalemergency', 'assets/icon/documentsadministration-icon.svg'),
                new MenuItem('Expertenstandards', 'administration/files/standards|experts', 'assets/icon/documentsadministration-icon.svg'),
                new MenuItem('Prophylaxestandards', 'administration/files/standards|prophylaxes', 'assets/icon/documentsadministration-icon.svg'),
              ]),
          ]),
        new MenuItem('Einstellungen', 'administration/settings', 'assets/icon/settings-icon.svg')
      ],
      true)
  ];


  constructor(public mainService: MainService) {
    mainService.loadBasicSettings();
  }


}

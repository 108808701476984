import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {SignObj} from '../../helpers/signobj';

@Component({
  selector: 'app-popupsign',
  templateUrl: './popupsign.component.html',
  styleUrls: ['./popupsign.component.scss'],
})
export class PopupsignComponent implements OnInit, AfterViewInit {

  @ViewChild('signblock')
  signblock: ElementRef;

  @ViewChild('signblockDesk')
  signblockDesk: ElementRef;

  @Input()
  messagesSt: string;
  @Input()
  sign: string;

  detectData: any;
  mouseIsDown = false;
  analyseData = {};
  analyseDataParse = '';
  time = 0;
  mobile = false;
  tick = 0;
  canvas: any;
  ctx: any;
  canvasWidth: any;
  canvasHeight: any;
  canvasData: any;
  signType = 'generic';
  lastPos = [];


  constructor(
    public popoverController: PopoverController,
  ) { }

  ngAfterViewInit(): void {
    this.canvas = this.signblock.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    this.canvasWidth = this.canvas.width;
    this.canvasHeight = this.canvas.height;
    this.canvasData = this.ctx.getImageData(0, 0, this.canvasWidth, this.canvasHeight);
    this.init();
  }
  init(){

    if(this.mobile){
      this.signblockDesk.nativeElement.style.display = 'none';
      this.signblock.nativeElement.style.display = 'block';
    }else{
      this.signblockDesk.nativeElement.style.display = 'block';
      this.signblock.nativeElement.style.display = 'none';

      this.initDesctopSign();
      setTimeout(()=>this.initDesctopSign(), 100);
    }
  }

  ngOnInit() {

  }

  onSuccess() {
    let canvas: any;
    if(this.mobile){
      canvas = this.signblock.nativeElement;
    }else{
      canvas = this.signblockDesk.nativeElement;
    }
    const dataUrl = canvas.toDataURL();
    const signObject: SignObj = {
      dataUrl,
      behavior: this.analyseData
    };
    this.popoverController.dismiss(signObject);
  }

  onAbort() {
    this.popoverController.dismiss({});
  }

  setMouseDown(event) {
    this.mouseIsDown = true;
    this.getPos(this.lastPos,event);

  }

  setMouseUp() {
    this.mouseIsDown = false;
    this.analyseDataParse = JSON.stringify(this.analyseData);
  }

  getPos(pos, event){
    const cX = event.clientX??event.changedTouches[0].clientX;
    const cY = event.clientY??event.changedTouches[0].clientY;
    const rect = event.target.getBoundingClientRect();
    const x = cX - rect.left;
    const y = cY - rect.top;
    pos[0] = Math.round(x);
    pos[1] = Math.round(y);
  }
  mouseMove(event) {
    const pos = [];
    this.getPos(pos, event);
    this.tick++;
    if(this.mouseIsDown){
      setTimeout(()=>{
        this.analyseData[this.time + 'ms'] = {
          xPos: pos[0],
          yPos: pos[1]
        };
        this.time +=100;
      }, 100);
      this.draw(this.ctx, pos);
    }

  }

  draw(context, p){
    context.beginPath();
    context.moveTo(this.lastPos[0], this.lastPos[1]);
    context.lineTo(p[0], p[1]);
    context.stroke();
    this.lastPos = p;

  }

  updateCanvas(ctx, canvasData) {
    ctx.putImageData(canvasData, 0, 0);
  }

  initDesctopSign(){
    const canvas = this.signblockDesk.nativeElement;
    const ctx = canvas.getContext('2d');
    ctx.font = 'bold 30px "SignOne"';
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillText(this.sign, 10, 50);
  }

  test(event) {
    this.mouseMove(event);
  }

  togleChange($event: any) {
    this.mobile = !this.mobile;
    this.init();
  }
}

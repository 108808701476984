import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Creds} from './models/Creds';
import {Token} from './models/Token';
import {CookieService} from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import {HttpService} from "./http.service";
@Injectable({
  providedIn: 'root'
})
export class TokenService {

  jwt = new JwtHelperService();
  constructor(private http: HttpClient, private cookie: CookieService) { }


  async getLogin(creds: Creds): Promise<Token|undefined>{
    let log = true;
      const token = (await this.http.post(HttpService.appfullhost + 'login', creds).toPromise().catch(()=> log = false)) as Token;
      if(log){
        return token;
      }else{
       return undefined;
      }
  }

  saveToken(token: Token): boolean{
    Object.keys(token).forEach((key: string) =>{
      this.cookie.set(key, token[key], {path: '/'});
    });
    return true;
  }

  getToken(): Token{
    const token = new Token();
    token.ACCESS_TOKEN = this.cookie.get('ACCESS_TOKEN');
    token.REFRESH_TOKEN = this.cookie.get('REFRESH_TOKEN');
    return token;
  }

  deleteToken(): boolean{
    Object.keys(this.getToken()).forEach((key: string) =>{
      this.cookie.delete(key, '/');
    });
    return true;
  }

  async logout(): Promise<boolean>{
    await this.http.get(HttpService.appfullhost + 'logout').toPromise().catch((e)=>console.log('Err'));
    this.deleteToken();
    return true;
  }

  async refreshToken(): Promise<Token|undefined>{
    return await this.http.get(HttpService.appfullhost + 'token').toPromise() as Token;
  }

  getDecodetAccessToken(): any {
    return this.jwt.decodeToken(this.getToken().ACCESS_TOKEN);
  }

  checkIfExpired(token: string): boolean{
    return this.jwt.isTokenExpired(token);
  }
}

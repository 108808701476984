export class MenuItem {
  constructor(
    public title: string,
    public url: string,
    public icon: string,
    public items: MenuItem[] = [],
    public admin = false,
    public expanded = false,
    public type: string = 'svg',
    ) {
  }
}

import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {MenuItem} from '../../models/menu/menuItem';
import {MainService} from '../../main.service';

@Component({
  selector: 'app-xm-menuitem',
  templateUrl: './xm-menuitem.component.html',
  styleUrls: ['./xm-menuitem.component.scss'],
})
export class XmMenuitemComponent implements OnInit, AfterViewInit {


  @Input()
  menuItem: MenuItem;

  @Input() offset: number = 0;

  constructor(public mainService: MainService) {
  }

  ngOnInit() {}

  ngAfterViewInit(): void {

  }

}

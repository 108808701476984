import { Injectable } from '@angular/core';
import {User} from './models/user';
import {TokenService} from './token.service';
import {Creds} from './models/Creds';
import {HttpService} from './http.service';
import {Xmfile} from './models/xmfile';
import {UserfilesConfirms} from './models/userfilesConfirms';
import {PopoverController, ToastController} from '@ionic/angular';
import {Settings} from './models/settings';
import {ReplaySubject} from 'rxjs';
import {Message} from './models/message';
import {SignObj} from './helpers/signobj';
import {PopupmessageComponent} from './common/popupmessage/popupmessage.component';
import {PopupQuestionComponent} from './common/popupquestion/popupquestion.component';
import {PopupsignComponent} from './common/popupsign/popupsign.component';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  user: User;
  settings: Settings;
  hasAccess =  'none';
  loadBasicEvent: ReplaySubject<boolean>;
  basicSettings: Settings;
  userfilesconfirms: UserfilesConfirms[] = [];


  constructor(
    public tokenService: TokenService,
    public httpS: HttpService,
    public toastController: ToastController,
    public popoverController: PopoverController

  ) {
    this.loadBasicEvent = new ReplaySubject<boolean>();
  }

  async login(creds: Creds): Promise<boolean>{
    const token = await this.tokenService.getLogin(creds);
    if(token){
      this.tokenService.saveToken(token);
      this.hasAccess = Access.access;
      await this.getMyUser();
      return true;
    }else{
      return false;
    }
  }

  async getMyUser(): Promise<User|undefined>{
    if(!this.user){
      this.user = await this.httpS.getUser(this.tokenService.getDecodetAccessToken().userid) as User;
      if(this.user){
        this.user = Object.assign(new User(), this.user);
      }
    }
    return this.user;
  }

  async logout(){
    await this.tokenService.logout();
    window.location.href = window.location.origin;
  }

  checkUserFile(userid: string, xmfileid: string): UserfilesConfirms|undefined{
    const res: UserfilesConfirms[] = this.userfilesconfirms.
    filter(uf => uf.userid === userid && uf.xmfileid === xmfileid);
    if(res.length > 0){
      return res[0];
    }else{
      return undefined;
    }
  }


  async getUserFilesConfirms(user: User): Promise<UserfilesConfirms[]> {
    this.userfilesconfirms = await this.httpS.getUserFilesConfirms(user);
    return this.userfilesconfirms;
  }

  async confirmUserFile(userFileConfirm: UserfilesConfirms, confirmed: boolean): Promise<UserfilesConfirms | undefined>{
    userFileConfirm.readed = true;
    userFileConfirm.confirmed = confirmed;
    let res: UserfilesConfirms;
    if(!userFileConfirm.ID){
      if(!confirmed){
        res = await this.httpS.readUserFile(userFileConfirm);
      }
    }else{
      if(confirmed){
        res = await this.httpS.confirmUserFile(userFileConfirm);
      }
    }
    if(res && res.ID){
      let userfileC = (this.userfilesconfirms.filter(fc=>fc.ID === res.ID))[0];
      if(userfileC){
        userfileC = res;
      }else{
        this.userfilesconfirms.push(res);
      }
      return res;
    }else{
      return  undefined;
    }
  }

  async getFiles(): Promise<Xmfile[]> {
    const fileList: Xmfile[] = await this.httpS.getFiles();
    return fileList;
  }


  async loadBasicSettings(): Promise<Settings>{
    const settings = (await this.httpS.getBasicSettings())[0];
    this.basicSettings = settings;
    this.loadBasicEvent.next(true);
    return this.basicSettings;
  }

  public async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 5000
    });
    toast.present();
  }

  async presentInfo(message: Message) {
    const popover = await this.popoverController.create({
      component: PopupmessageComponent,
      componentProps: {
        messagesSt: JSON.stringify(message)
      },
      cssClass: 'confirm-class',
      translucent: true,
    });
    await popover.present();
  }

  async presentQuestion(message: Message): Promise<{status: boolean}> {
    const popover = await this.popoverController.create({
      component: PopupQuestionComponent,
      componentProps: {
        messagesSt: JSON.stringify(message)
      },
      cssClass: 'confirm-class',
      translucent: true,
    });
    await popover.present();
    const status = (await popover.onDidDismiss()).data as {status: boolean};
    return status;
  }

  async presentSign(message: Message, fullName: string): Promise<SignObj> {
    const popover = await this.popoverController.create({
      component: PopupsignComponent,
      componentProps: {
        messagesSt: JSON.stringify(message),
        sign: fullName
      },
      cssClass: 'confirm-class',
      translucent: true,
    });
    await popover.present();
    const sign = (await popover.onDidDismiss()).data as SignObj;
    return sign;
  }

  saveData(blob, fileName) {
    const a: any = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  async getFile(fileO: Xmfile) {
    this.saveData(await this.httpS.getFile(fileO.name), fileO.name);
  }

}

export class Access {
  static none = 'none';
  static access = 'access';
  static refresh = 'refresh';
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {XmbuttonComponent} from './xmbutton/xmbutton.component';
import {IonicModule} from '@ionic/angular';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {PopupmessageComponent} from './popupmessage/popupmessage.component';
import {PopupQuestionComponent} from './popupquestion/popupquestion.component';
import {PopupsignComponent} from './popupsign/popupsign.component';
import {XmDatePickerComponent} from './xm-date-picker/xm-date-picker.component';
import {XmMultiselectComponent} from './xm-multiselect/xm-multiselect.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {XmMenuitemComponent} from './xm-menuitem/xm-menuitem.component';
import {RouterLink} from "@angular/router";




@NgModule({
  declarations: [
    XmbuttonComponent,
    PopupmessageComponent,
    PopupQuestionComponent,
    PopupsignComponent,
    XmDatePickerComponent,
    XmMultiselectComponent,
    XmMenuitemComponent
  ],
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    MatNativeDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    FormsModule,
    RouterLink
  ],
  exports: [
    XmbuttonComponent,
    PopupmessageComponent,
    PopupQuestionComponent,
    PopupsignComponent,
    XmDatePickerComponent,
    XmMultiselectComponent,
    XmMenuitemComponent,
    MatNativeDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    NgMultiSelectDropDownModule,
    NgSelectModule,
    IonicModule,
    CommonModule,
    FormsModule,
    RouterLink
  ]
})
export class MysharedModule { }

import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpEvent,  HttpRequest, HttpHandler} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Access, MainService} from './main.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private mainService: MainService) {
  }
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    if(httpRequest.url.indexOf('settings')>-1){
      return next.handle(httpRequest);
    }

    const auth = this.mainService.hasAccess === Access.none ? '' : 'Bearer ' +
      (this.mainService.hasAccess === Access.access ?
        this.mainService.tokenService.getToken().ACCESS_TOKEN: this.mainService.tokenService.getToken().REFRESH_TOKEN);
    const req  = httpRequest.clone({setHeaders: {'x-api-key': 'aaa', authorization : auth}});

    return next.handle(req);
  }
}

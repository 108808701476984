import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDatepicker} from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-xm-date-picker',
  templateUrl: './xm-date-picker.component.html',
  styleUrls: ['./xm-date-picker.component.scss'],
})
export class XmDatePickerComponent implements OnInit {


  @ViewChild('picker')
  picker: MatDatepicker<any>;

  @ViewChild('pickertext')
  pickertext: any;

  @Input()
  xmpickermode = 'day';

  @Input()
  xmdisabled = false;

  @Input()
  defaultdate: any = new Date();

  @Input()
  mindate: any = new Date(2019, 0,0);
  @Input()
  maxdate: any = new Date(2050, 0, 0);

  @Input()
  startAt: any = new Date();


  @Output()
  selectingDate: EventEmitter<PickerOneResult|PickerRangeResult> = new EventEmitter<PickerOneResult | PickerRangeResult>();

  xmpickericon: any;
  pickerstartview: any;

  selectedDate: string;
  selectedTarget: PickerOneResult;
  selectedRange: PickerRangeResult;
  selectedRangeStart: any;
  selectedRangeStop: any;


  constructor(private dateAdapter: DateAdapter<any>) {
    this.selectedTarget = new PickerOneResult();
    this.selectedRange = new PickerRangeResult();

  }

  ngOnInit() {
    this.initPicker();
  }

  pickericonClick(): void {
    if(!this.xmdisabled){
      this.picker.open();
    }

  }

  pickerMonthSelect(event) {
    if(this.xmpickermode === 'month'){
      this.picker.close();
      this.selectedTarget.target = event;
      this.returnResult();
    }
  }

  pickerDaySelect() {
    if(this.xmpickermode === 'day'){
      this.picker.close();
      this.returnResult();
    }
  }

  pickerYearSelect(event) {
    if(this.xmpickermode === 'year'){
      this.picker.close();
      this.selectedTarget.target = event;
      this.returnResult();
    }
  }

  pickerRangeSelect(event) {
    if(!this.selectedRangeStart || !this.selectedRangeStop) {
      return;
    }
    this.selectedRange.start =  new Date(this.selectedRangeStart);
    this.selectedRange.stop =  new Date(this.selectedRangeStop);
    if(this.xmpickermode === 'rangeday'){
      this.picker.close();
      this.selectedTarget.target = event;
      this.returnResult();
    }
  }

  public getTargetString(): string{
    let options = {};
    const value = this.selectedTarget.target;
    switch (this.xmpickermode){
      case 'day':
        options = {  year: 'numeric', month: '2-digit' , day: '2-digit'};
        break;
      case 'month':
        options = {  year: 'numeric', month: 'long'};
        break;
      case 'year':
        options = {  year: 'numeric'};
        break;
    }
    return value.toLocaleString('de-DE',options);
  }

  public select(date: Date){
    this.selectedTarget.target = date;
    this.selectedDate = date.toISOString();
    this.pickertext.textContent = this.getTargetString();
  }


  public getRangeString() {
    const options: any = {  year: 'numeric', month: '2-digit' , day: '2-digit'};
    return this.selectedRange.start.toLocaleString('de-DE',options) +
      ' - ' +
      this.selectedRange.stop.toLocaleString('de-DE',options);
  }

  public checkSingleMode(): boolean {
    switch (this.xmpickermode){
      case 'day':
      case 'month':
      case 'year':
        return true;
      default: return false;
    }
  }

  private initPicker() {
    switch (this.xmpickermode){
      case 'day':
        this.initPickerData('/assets/icon/daypicker.svg', 'month');
        break;
      case 'month':
        this.initPickerData('/assets/icon/daypicker.svg', 'year');
        break;
      case 'year':
        this.initPickerData('/assets/icon/daypicker.svg', 'multi-year');
        break;
      case 'rangeday':
        this.initRangeData('/assets/icon/daypicker.svg', 'month');
        break;
    }
  }

  private initPickerData(pieckerIcon: string, pickerstartview: string) {
    this.selectedTarget.target = new Date(this.defaultdate);
    this.selectedDate = this.selectedTarget.target.toISOString();
    this.xmpickericon = pieckerIcon;
    this.pickerstartview = pickerstartview;
  }

  private initRangeData(pieckerIcon: string, pickerstartview: string) {
    this.xmpickericon = pieckerIcon;
    this.pickerstartview = pickerstartview;
    this.selectedRangeStart = this.startAt || this.mindate || new Date();
    this.selectedRangeStop = this.startAt || this.mindate || new Date();
    this.selectedRange.start = this.selectedRangeStart;
    this.selectedRange.stop = this.selectedRangeStart;
  }

  private returnResult(){
    if(this.xmpickermode === 'day' || this.xmpickermode === 'month'
      || this.xmpickermode === 'year'){
      this.selectingDate?.emit(this.selectedTarget);
    }else{
      this.selectingDate?.emit(this.selectedRange);
    }
  }

}



export class PickerOneResult  {
  private targetP: Date;
  constructor() {
    this.target = new Date();
  }
  // @ts-ignore
  set target(value: Date) {
    const dValue = new Date(value);
    if([22,23,0].some(a=>a === dValue.getHours())){
      this.targetP = new Date(dValue.getTime() + 6*60*60*1000);
    }else{
      this.targetP = dValue;
    }
  }
  // @ts-ignore
  get target(): Date {
    return this.targetP;
  }

}
export class PickerRangeResult{
  private stopP: Date;
  private startP: Date;
  constructor() {
    this.start = new Date();
    this.stop = this.startP;
  }
  // @ts-ignore
  get stop(): Date {
    return this.stopP;
  }
  // @ts-ignore
  set stop(value: Date) {
    const dValue = new Date(value);
    if([22,23,0].some(a=>a === dValue.getHours())){
      this.stopP = new Date(dValue.getTime() + 6*60*60*1000);
    }else{
      this.stopP = dValue;
    }
  }
  // @ts-ignore
  get start(): Date {
    return this.startP;
  }
  // @ts-ignore
  set start(value: Date) {
    const dValue = new Date(value);
    if([22,23,0].some(a=>a === dValue.getHours())){
      this.startP = new Date(dValue.getTime() + 6*60*60*1000);
    }else{
      this.startP = dValue;
    }
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CanloadappGuard} from './canloadapp.guard';
import {CheckAdminGuard} from './checkadmin.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'user',
    pathMatch: 'full'
  },
  { path: 'auth',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule)
  },
  { path: 'viewer',
    loadChildren: () => import('./viewer/viewer.module').then( m => m.ViewerPageModule), canLoad : [CanloadappGuard]
  },
  {
    path: 'administration',
    loadChildren: () => import('./administration/administration.module').then( m => m.AdministrationPageModule), canLoad : [CheckAdminGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then( m => m.UserPageModule), canLoad : [CanloadappGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

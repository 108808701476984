import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-xmbutton',
  templateUrl: './xmbutton.component.html',
  styleUrls: ['./xmbutton.component.scss'],
})
export class XmbuttonComponent implements OnInit {

  @Input()
  xmcolor: string;
  @Input()
  xmcontent: string;
  @Input()
  xmicon: string;
  @Output()
  xmclick: EventEmitter<any> = new EventEmitter<any>();

  iconSrc: any;


  constructor() {

  }

  ngOnInit() {
    this.iconSrc = `/assets/icon/` + this.xmicon;
  }

  async buttonClick($event: MouseEvent) {
    this.xmclick?.emit();
  }
}

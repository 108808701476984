import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {IDropdownSettings, MultiSelectComponent} from 'ng-multiselect-dropdown';
import {ListItem} from 'ng-multiselect-dropdown/multiselect.model';

@Component({
  selector: 'app-xm-multiselect',
  templateUrl: './xm-multiselect.component.html',
  styleUrls: ['./xm-multiselect.component.scss'],
})
export class XmMultiselectComponent implements OnInit{


  @ViewChild('selecter')
  selecter: MultiSelectComponent;

  @Input()
  dropdownList: any[];
  @Input()
  selectedItems: any[] ;

  @Input()
  keyValue: string;
  @Input()
  displayValue: string;

  @Output() select: EventEmitter<any[]> = new EventEmitter<any[]>();



  dropdownSettings: IDropdownSettings = {};
  constructor() { }


  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: this.keyValue,
      textField: this.displayValue,
      selectAllText: 'Alle wählen',
      unSelectAllText: 'Alle abwählen',
      itemsShowLimit: 50,
      allowSearchFilter: true
    };

  }

  onItemSelect(e: ListItem) {
    this.select.emit(this.selectedItems);
  }

  onSelectAll(e: Array<ListItem>) {
    setTimeout(()=>{
      this.selecter.closeDropdown();
      this.select.emit(this.selectedItems);
      },10);
  }

  onClose(e: ListItem) {
  }

}

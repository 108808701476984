import {
  CanLoad,
  Route, Router,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {Observable, from} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Access, MainService} from './main.service';
import {Creds} from './models/Creds';
import {User} from './models/user';

@Injectable({
  providedIn: 'root'
})
export class CheckAdminGuard {

  constructor(private cookie: CookieService,
              private http: HttpClient,
              private router: Router,
              private mainService: MainService) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    return from(this.checkLogin());
  }

  async checkLogin(): Promise<boolean>{
    if(await this.isAccessTokenValid(this.mainService.tokenService.getToken().ACCESS_TOKEN)){

      this.mainService.hasAccess = Access.access;
      if(!this.mainService.user){
        await this.mainService.getMyUser();
      }
      return this.mainService.user.isAdmin();
    }else{
      this.mainService.hasAccess = Access.none;
      await this.router.navigateByUrl('auth');
      return false;
    }
  };

  private async isAccessTokenValid(accesstoken: string): Promise<boolean> {
    if(!accesstoken) {
      return false;
    }
    if(this.mainService.tokenService.checkIfExpired(accesstoken)){
      if(this.mainService.tokenService.checkIfExpired(this.mainService.tokenService.getToken().REFRESH_TOKEN)){
        return false;
      }
      this.mainService.hasAccess = Access.refresh;
      const token = await this.mainService.tokenService.refreshToken();
      if(token){
        this.mainService.tokenService.saveToken(token);
        return true;
      }
    }
    return true;
  }
}

import {Wishday} from './Wishday';
import {Vacation} from './vacation';
import {Timeline} from './timeline';
import {Workplane} from './workplane';
import {Xmfile} from "./xmfile";
import {Teamrole} from "./team/teamrole";

export class User {
  [param: string]: any;
  ID: string;
  Name: string;
  Lastname: string;
  address?: string;
  city?: string;
  postcode?: string;
  email: string;
  telefone: string;
  userRoles: any[] = [];
  userTeamRoles: Teamrole[] = undefined;

  userWishdays: Wishday[];
  userVacations: Vacation[];
  userTimelines: Timeline[];
  userWorkplanes: Workplane[];
  vacationdays: number;
  carlicense: string;

  isAdmin = ()=>{
    if(this.userRoles){
      return this.userRoles.filter(ur=> ur.name === 'admin').length > 0;
    }
    return false;
  };

  hasRole = (role: string)=>{
    if(this.userRoles){
      return this.userRoles.filter(ur=> ur.name === role).length > 0;
    }
    return false;
  };

  getWishDaysStrings(): string[]{
    if(! this.userWishdays){
      return [];
    }
    return this.userWishdays.map(wd=> wd.wishday);
  }

  getWishDaysSort(): Wishday[]{
    if(!this.userWishdays){
      return [];
    }
    return this.userWishdays.sort((a,b) => new Date(b.wishday).getTime() - new Date(a.wishday).getTime());
  }

  getNeuWishdays(): number{
    if(! this.userWishdays){
      return 0;
    }
    return this.userWishdays.filter(wd=>!wd.approval).length;
  }

  getFullName(): string{
    return ((this.Lastname??'') + ' ' + this.Name);
  }

  getFullNameForFile(): string{
    return ((this.Name??'') + '_' + this.Lastname);
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {Message} from '../../models/message';

@Component({
  selector: 'app-popupmessage',
  templateUrl: './popupquestion.component.html',
  styleUrls: ['./popupquestion.component.scss'],
})
export class PopupQuestionComponent implements OnInit {

  @Input()
  messagesSt: string;

  message: Message;
  constructor(
    public popoverController: PopoverController
  ) { }

  ngOnInit() {
    this.message = JSON.parse(this.messagesSt);
  }

  onSuccess() {
    this.popoverController.dismiss({status:true});
  }
  onAbort() {
    this.popoverController.dismiss({status:false});
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {Message} from '../../models/message';

@Component({
  selector: 'app-popupmessage',
  templateUrl: './popupmessage.component.html',
  styleUrls: ['./popupmessage.component.scss'],
})
export class PopupmessageComponent implements OnInit {

  @Input()
  messagesSt: string;

  message: Message;
  constructor(
    public popoverController: PopoverController
  ) { }

  ngOnInit() {
    this.message = JSON.parse(this.messagesSt);
  }

  onClick(){
    this.popoverController.dismiss();
  }

}
